import { render, staticRenderFns } from "./BaseBtn.vue?vue&type=template&id=4fed0598&scoped=true"
import script from "./BaseBtn.vue?vue&type=script&lang=js"
export * from "./BaseBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fed0598",
  null
  
)

export default component.exports